import React, { useRef } from "react";
import Layout from "../../../components/common/Layout";
import Meta from "../../../components/common/Meta";

const BrochurePage = () => {
  const meta = {
    title: "Freshworks 無料トライアル｜正規販売代理店（株）Digital Stacks",
    description:
      "ビジネス向けのクラウドベースのソフトウェアソリューション「Freshworks」を無料でお試しください。",
    pathname: "/tp/freshworks/free-trial/",
  };

  const iframeRef = useRef<HTMLIFrameElement>(null);

  return (
    <Layout>
      <Meta meta={meta} />
      <div className="mt-16 mb-0 sm:mb-[52px] lg:mb-0 xl:mb-[52px]">
        <div className="flex gap-x-5 xl:gap-x-[45px] flex-col gap-y-10 md:flex-row ">
          <div className="flex-1 pl-4 sm:px-4 md:pr-0 lg:pl-[100px]">
            <h1 className="mb-5 font-bold text-[40px] leading-[60px] text-dsc-title">
              小規模企業から大規模企業まで50,000社以上が利用するビジネスソフトウェア
            </h1>
            <h2 className="text-[26px] font-bold leading-[30px] text-dsc-title mb-5">
              Freshworksを無料でお試しください。
            </h2>
            <p className="text-base leading-[27px] text-black">
              クレジットカードは必要ありません。付帯条件なし。
              <br /> IT、カスタマー
              サービス、セールス、マーケティング担当者、人事担当者が業務を遂行し、顧客を満足させるためのシンプルなソリューションを企業が求めているかどうかにかかわらず、Freshworksはニーズに合った適切なツールです。21日間の無料トライアルを今すぐゲット！
            </p>
          </div>
          <div className="flex-1 lg:pr-[100px]">
            <div className="px-4 md:pl-0">
              <h2 className="text-[22px] font-bold leading-7 text-black mb-5">
                Freshworksの無料トライアルのお申し込み
              </h2>
              <p className="text-sm text-black mb-5 leading-7">
                ご利用のインターネット環境によりフォームが表示されない場合がございます。入力フォームが表示されない場合は、こちらのメールアドレスまでお問い合わせください。
                <a
                  href="mailto:info@digitalstacks.net"
                  className="text-[#3478B2] underline"
                >
                  info@digitalstacks.net
                </a>
              </p>
              <p className="text-sm leading-7 text-[#FF0000] mb-[10px]">
                *は必須項目
              </p>
            </div>
            <div className="max-w-[700px] ml-0">
              <iframe
                ref={iframeRef}
                loading="lazy"
                frameBorder="0"
                title="Contact Form"
                className="min-h-[1100px] sm:min-h-[980px] md:min-h-[1100px] xl:min-h-[984px]"
                style={{
                  width: "100%",
                  overflow: "hidden",
                }}
                src="https://forms.zohopublic.com/digitalstacks/form/Freshworks/formperma/OpGhTC_EbLVoP4vI0KW3b-7_tEZSCFnIrSFjcXhpEYA?zf_rszfm=1"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BrochurePage;
